import { useEffect } from "react";

import { json } from "@remix-run/cloudflare";
import type { ShouldRevalidateFunction } from "@remix-run/react";
import { useFetcher } from "@remix-run/react";

import { getClient } from "~/.server/contentful.server";
import { SWR } from "~/commerce-sap/.server/caching.server";

import type { TypeRewardsBannerCartSkeleton } from "~/contentful/compiled";

export const loader = async ({ request }: LoaderArgs) => {
  try {
    const url = new URL(request.url);
    const preview = !!url.searchParams.get("preview");
    const client = getClient(preview);
    const swrContent = SWR("rewards-static-content");

    const rewardsContent = await swrContent(async () => {
      return await client.getEntries<TypeRewardsBannerCartSkeleton>({
        content_type: "rewardsBannerCart",
        "fields.name[in]": [
          "RewardsBannerMember",
          "RewardsBannerGuest",
          "RewardsBannerRegistered",
        ],
        limit: 3,
        include: 5,
      });
    }, []);

    return json({ rewardsContent });
  } catch (error) {
    console.error(error);
    throw new Response("Not Found", { status: 404 });
  }
};

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  return false;
};

export const useLoadRewards = () => {
  const fetcher = useFetcher<typeof loader>();

  useEffect(() => {
    fetcher.load("/resources/member-rewards");
  }, []);

  return { fetcher };
};
